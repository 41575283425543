import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import Header from "../components/Header"
import Container from "../components/container"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const PostBlock = styled.div`
  margin: 4rem 0;
`
const PostTitle = styled.h3`
  margin: 0 0 0.7rem 0;
  a {
    text-decoration: none;
    border-bottom: 2px solid #ff4136;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    font-family: "Inter", "sans-serif";
    line-height: 1.5;
  }
`
const PostDate = styled.h6`
  margin: 0.7rem 0;
  font-weight: 400;
`

export const query = graphql`
  query HomeQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 100)
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`

export default function Home({ data }) {
  const { edges } = data.allMarkdownRemark
  return (
    <Fragment>
      <Seo title="Mixed Bag - Karan Navani's blog" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mixed Bag - Karan Navani's blog</title>
        <link rel="canonical" href="http://blog.karannavani.com" />
      </Helmet>
      <Container>
        <Header />
        {edges.map(edge => {
          const { excerpt, frontmatter } = edge.node
          const featuredImgFluid =
            frontmatter.featuredImage.childImageSharp.gatsbyImageData

          return (
            <PostBlock key={frontmatter.path}>
              <GatsbyImage
                image={featuredImgFluid}
                style={{ maxHeight: "400px" }}
                imgStyle={{ objectFit: "cover" }}
                alt={frontmatter.alt ? frontmatter.alt : ""}
              />
              <PostDate>{frontmatter.date}</PostDate>
              <PostTitle>
                <Link to={frontmatter.path}>{frontmatter.title}</Link>
              </PostTitle>
              <p>{excerpt}</p>
            </PostBlock>
          )
        })}
      </Container>
    </Fragment>
  )
}
