import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"

const Title = styled.h1`
font-weight: 600;
a {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }
`
const Subheading = styled.h3`
 font-weight: normal;
`

const Hyperlink = styled.a`
  text-decoration: none;
  border-bottom: 2px solid #ff4136;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.5;
`

const TitleAndDescription = ({ data }) => {
  const title = data.site.siteMetadata.title

  return (
    <div>
      <Title>
        <Link to="/">
          {title}
        </Link>
      </Title>

      <Subheading>
        An assortment of <Hyperlink href="https://karannavani.com" target="blank">
          my
        </Hyperlink> thoughts. No discernable theme.
      </Subheading>

    </div>
  )
}

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => <TitleAndDescription data={data} />}
    />
  )
}

export default Header
